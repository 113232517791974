<template>
  <div
    class="relative bg-cover bg-center h-230 md:h-364"
    :style="{'background-image': `url(${isLargeScreen ? bgImg : bgMobileImg})`}"
  >
    <div class="w-full absolute z-10 bottom-22 gap-2 md:(bottom-50 gap-6 flex-nowrap flex-row) flex flex-col  items-center justify-center">
      <a
        v-for="card in cards"
        class="md:(w-82 h-24) h-12 w-full border-5 border-red"
        :href="`https://${card.url}`"
        target="_blank"
      />
    </div>
    <div class="w-full absolute z-10 bottom-5 md:bottom-16 flex justify-center">
      <a
        class="md:(w-260 h-30) h-16 w-full border-5 border-red"
        href="https://t.me/maybet114"
        target="_blank"
      />
    </div>
  </div>
</template>
<script setup>
import bgImg from '@/assets/domain/bg.png'
import bgMobileImg from '@/assets/domain/bg-mobile.png'
import { useMediaQuery } from '@vueuse/core'

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const cards = [
  {
    url: 'may-01.com'
  },
  {
    url: 'may-02.com'
  },
  {
    url: 't.me/+irYmXtdEPIlhYjg1'
  },
]
</script>
